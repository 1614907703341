import React from "react"
import { Paragraph, Link } from "evergreen-ui"
import StartButton from "../components/StartButton"

export default ({ location }) => {
  return (
    <>
      <Paragraph size={500} marginTop="default">
        Sehr geehrter Kunde,
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        Vielen Dank für Ihr Interesse, dem europäischen Goodyear E-Commerce
        Montagepartner Netzwerk beizutreten. Wie Sie wissen, bedient das
        Netzwerk Verbraucher, die online über eine Goodyear E-Commerce Website
        gekauft haben. Gleichzeitig bietet Ihnen Goodyear eine marktgerechte
        Vergütung für die Reifenmontage. Darüber hinaus bietet Ihnen Goodyear
        für jede Transaktion eine Reifenausgleichsmarge. Bitte schauen Sie sich
        das beigefügte{" "}
        <Link href="/videos/de/video.mp4" target="_blank" size={500}>
          Video
        </Link>{" "}
        und{" "}
        <Link
          href="/manuals/shop_goodyear.de_handbuch_montagepartner.pdf"
          target="_blank"
          size={500}
        >
          Handbuch
        </Link>{" "}
        an, um sich näher mit dem Programm vertraut zu machen.
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        Um sich für das Programm anzumelden, führen Sie bitte alle Schritte des
        Online Registrierungsprozesses durch.
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        Nach Abschluss der Online Registrierung erhalten Goodyear und Sie eine
        Kopie aller von Ihnen übermittelten Daten. Ab diesem Moment wird
        Goodyear Ihre Registrierung prüfen. Nach der Evaluierung informiert Sie
        Goodyear, wenn Sie als Montagepartner aktiviert sind.
      </Paragraph>
      <Paragraph size={500} marginTop="default" marginBottom="default">
        Nochmals vielen Dank für Ihr Interesse am Beitritt zum Goodyear
        eCommerce Montage Partner Netzwerk. Wir hoffen, Sie bald als
        Montagepartner begrüßen zu dürfen.
      </Paragraph>
      <StartButton search={location.search} />
    </>
  )
}
