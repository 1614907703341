import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Button } from "evergreen-ui"
import { DealerContext } from "../state/dealerProvider.js"

export default ({ search }) => {
  const { i18n, t } = useTranslation()
  const context = useContext(DealerContext)
  useEffect(() => {
    context.init()
  }, [])
  return (
    <Button
      is="a"
      appearance="primary"
      height={40}
      disabled={context.data.loading}
      href={`/${i18n.language}/basicdata${search}`}
    >
      {t("Become a Goodyear installer")}
    </Button>
  )
}
